export const SECTIONS = [
	{
		name: 'About',
	},
	{
		name: 'Projects',
	},
	{
		name: 'Contact',
	},
	{
		name: 'Resume',
	},
];

export const NAME = 'Tom Chestnut';
